<template>
  <div class="sports_page">
    <div class="cor-select">
      <div class="cor-select-left">
        <el-select
          v-model="className"
          placeholder="请选择"
          @change="changeClass"
        >
          <el-option
            v-for="item in classList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="cor-select-right">
        小班请选择难度等级1进行测试，中班请选择难度等级2进行测试，大班请选择难度等级3进行测试
      </div>
    </div>
    <div class="custTab" v-loading="loading">
      <table class="layui-table" lay-skin="nob">
        <thead>
          <tr>
            <th>测试项目</th>
            <th>测试等级</th>
            <th>测试名称</th>
            <th>学期初状态</th>
            <th>学期末状态</th>
            <th>教案</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <!-- <td rowspan="3" v-show="(index + 1) % 3 == 1" align="center">
              {{ item.testingSports.testNo }}.{{ item.testingSports.title }}
            </td> -->
            <td rowspan="1" align="center">
              {{ item.testingSports.testNo }}.{{ item.testingSports.title }}
            </td>
            <td>难度等级{{ item.testingSports.testlevel }}</td>
            <td>{{ item.testingSports.name }}</td>
            <td>{{ item.finish ? "已完成" : "未完成" }}</td>
            <td>{{ item.open ? "已完成" : "未完成" }}</td>
            <td>
              <div class="btn-play tab" @click="playVideo(item.testingSports)">
                <img src="../../../../assets/img/lesson/play2.png" alt="" />
                <span>播放</span>
              </div>
            </td>
            <td class="text-center">
              <div class="btn btn-start tab" @click="toTestCheck(item)">
                开始测试
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "sports",
  components: {},
  data() {
    return {
      gradeType: "", // 班级类型
      gradeId: "", // 班级ID
      list: [],
      toList: [],
      loading: true,
      classList: [],
      className: "",
    };
  },
  created() {},
  mounted() {
    //this.getYDList();
    this.getClassList();
  },
  methods: {
    changeClass(val) {
      console.log(val);
      this.gradeId = val;
      this.classList.map((item) => {
        if (val == item.id) {
          this.getYDList(val, item.type);
        }
      });
    },
    //  获取上课班级
    getClassList() {
      let data = {
        teacherId: this.$store.state.userInfo.teacherId,
      };
      this.api.nAdmin.findGradesOfTid(data).then((res) => {
        console.log(res, "班级列表");
        this.classList = res.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          };
        });
        this.className = res.data[0].name;
        this.gradeId = res.data[0].id;
        this.getYDList(res.data[0].id, res.data[0].type);
        //this.findTestGrade(res.data[0].id);
      });
    },
    getYDList(id, type) {
      let data = {
        semesterId: this.$store.state.userInfo.semesterId,
        gradeId: id,
        isEnd: 0,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeType: type,
      };
      this.gradeType = type;
      console.log(data);
      this.api.admin.findSportsTest(data).then((res) => {
        console.log(res, "列表测试");

        if (res.flag) {
          this.loading = false;
        }
        this.list = res.data;
        // switch (this.$route.query.type) {
        //   case "0": //大班
        //     this.list.forEach((item) => {
        //       if (item.testingSports.testlevel === "3") {
        //         this.toList.push(item);
        //       }
        //     });
        //     break;
        //   case "1": //中班
        //     this.list.forEach((item) => {
        //       if (item.testingSports.testlevel === "2") {
        //         console.log(item);

        //         this.toList.push(item);
        //       }
        //     });
        //     break;
        //   case "2": //小班
        //     this.list.forEach((item) => {
        //       if (item.testingSports.testlevel === "1") {
        //         this.toList.push(item);
        //       }
        //     });
        //     break;
        // }
      });
    },
    playVideo(item) {
      console.log(item);
      this.$router.push({
        path: "sportsInfo",
        query: {
          id: item.id,
          isSports: true,
          gradeId: this.gradeId,
          testlevel: item.testlevel,
          gradeType: this.gradeType,
        },
      });
    },
    toTestCheck(item) {
      console.log(this.gradeType);
      this.$router.push({
        path: "exerciseTestB",
        query: {
          gradeId: this.gradeId,
          gradeType: this.gradeType,
          isSports: true,
          sportId: item.testingSports.id,
          testlevel: item.testingSports.testlevel,
        },
      });
    },
    // 0 大班 1 中班 2 小班
    // level 1 小班 level 2 中班  level 3 大班
  },
};
</script>
<style lang="scss" scoped>
.cor-select {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  .cor-select-left {
    width: 130px;
    height: 38px;
    /deep/ .el-input__inner {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .cor-select-right {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ba3037;
    opacity: 0.9;
    margin-top: 12px;
  }
}
.sports_page {
  width: 100%;
  .tabTit {
    min-height: 0.9rem;
    box-sizing: border-box;
    /* text-align: right; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .tabTit .tips {
    font-size: 0.2rem;
    /* position: absolute;
    left: 0; */
  }

  .tabTit .text {
    line-height: 0.3rem;
    font-weight: 700;
    color: #cb1b31;
    font-size: 0.3rem;
    /* padding-right: .5rem;
    text-align: right;
    float: right; */
  }

  .tabTit .searchdiv {
    display: flex;
  }

  .tabTit .searchdiv .btn-search {
    margin-right: 0.2rem;
  }

  .tabTit .btn-add,
  .tabTit .btn-blue {
    padding: 0rem 0.4rem;
    height: 38px;
    line-height: 38px;
    font-size: 0.22rem;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    border: none;
  }

  .tabTit .btn-add {
    background: #cb1b31;
    color: #fff;
  }

  .tabTit .btn-blue {
    background: #228cec;
    color: #fff;
  }
  .custTab {
    /* padding: 0rem .5rem; */
    position: relative;
    margin-bottom: 0.4rem;
  }
  .custTab .layui-table {
    padding: 0;
    margin: 0;
    width: 100%;
    table-layout: fixed;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 4px;
  }
  .custTab .layui-table {
    padding: 0;
    margin: 0;
    th,
    td {
      text-align: center;
    }
  }
  .custTab .layui-table tr th:first-child {
    border-radius: 4px 0 0 0;
  }
  .custTab .layui-table tr th:last-child {
    border-radius: 0 4px 0 0;
  }
  .custTab thead th {
    font-size: 16px !important;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.9;
    background: #ba3037;
    height: 42px;
    padding: 0;
  }
  .custTab tbody tr:last-child {
    border-bottom: none;
  }
  .custTab tbody tr {
    border-bottom: solid 1px #dddddd;
  }

  .custTab tbody td {
    height: 42px;
    font-size: 14px !important;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    opacity: 0.9;
    padding: 0;
  }

  .custTab tbody tr td:last-child {
    padding-right: 0;
  }

  .custTab .btn-start {
    color: #ba3037;
  }

  .custTab .btn-play {
    width: 69px;
    height: 24px;
    background: linear-gradient(232deg, #b93037, #ef525b);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #fff;
    margin: 0 auto;
    img {
      width: 19px;
      height: 19px;
      margin-left: 3px;
    }
    span {
      margin-left: 4px;
    }
  }
}
.pad-box-content {
  padding: 0 10px;
}
</style>